@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300&display=swap");
b .swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

/* 3D Effects */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 200ms top, 200ms -webkit-transform;
  transition: 200ms top, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top, 200ms -webkit-transform;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms left, 200ms -webkit-transform;
  transition: 200ms left, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left, 200ms -webkit-transform;
}

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform;
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar, .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000;
}

.swiper-pagination-lock {
  display: none;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

[data-simplebar] {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  -o-transition: opacity 0s linear;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
  SCSS Functions
\* ------------------------------------------------------------ */
/* example usage*/
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */
* {
  padding: 0;
  margin: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

template {
  display: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

img, iframe, video, audio, object {
  max-width: 100%;
}

img, iframe {
  border: 0 none;
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

b, strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a, button, input[type=submit], input[type=button], input[type=reset], input[type=file], input[type=image], label[for] {
  cursor: pointer;
}

a[href^=tel], button[disabled], input[disabled], textarea[disabled], select[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type=text], input[type=password], input[type=date], input[type=datetime], input[type=datetime-local], input[type=month], input[type=week], input[type=email], input[type=number], input[type=search], input[type=tel], input[type=time], input[type=url], input[type=color], textarea, a[href^=tel] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

button, select {
  text-transform: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul, nav ol {
  list-style: none outside none;
}

input::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

input::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

input::placeholder {
  color: inherit;
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

textarea:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

textarea::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

textarea::placeholder {
  color: inherit;
  opacity: 1;
}

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

input[type=text], input[type=button], input[type=tel], input[type=email], input[type=submit], input[type=date], input[type=search], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
  opacity: 1 !important;
}

input::-moz-placeholder {
  opacity: 1 !important;
}

input:-ms-input-placeholder {
  opacity: 1 !important;
}

input::-ms-input-placeholder {
  opacity: 1 !important;
}

input::placeholder {
  opacity: 1 !important;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
}

textarea {
  border-radius: 0;
}

/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
html {
  font-size: 10px;
}

body {
  min-width: 320px;
  background: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a[href^=tel] {
  text-decoration: none;
}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  margin-bottom: calc(1.5em / 2);
  line-height: 1.2;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
    line-height: 1.34;
  }
}
.h1, h1 {
  font-size: 7.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h1, h1 {
    font-size: 4.6rem;
  }
}
.h2, h2 {
  font-size: 5.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h2, h2 {
    font-size: 3.6rem;
  }
}
.h3, h3 {
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .h3, h3 {
    font-size: 2.4rem;
  }
}
.h4, h4 {
  font-size: 2.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.h5, h5 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
}

.h6, h6 {
  font-size: 1.4rem;
}

p, ul, ol, dl, hr, table, blockquote {
  margin-bottom: 1.5em;
}

h1[class], h2[class], h3[class], h4[class], h5[class], h6[class], h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, ul:last-child, ol:last-child, dl:last-child, table:last-child, blockquote:last-child {
  margin-bottom: 0;
}

small {
  font-size: 1.2rem;
}

/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear:after {
  content: "";
  line-height: 0;
  display: table;
  clear: both;
}

/* sr-only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*  Notext  */
.notext {
  white-space: nowrap;
  text-indent: 100%;
  text-shadow: none;
  overflow: hidden;
}

.notext-old {
  font-size: 0;
  line-height: 0;
  text-indent: -4000px;
}

/*  Hidden  */
[hidden], .hidden {
  display: none;
}

/*  Disabled  */
[disabled], .disabled {
  cursor: default;
}

/*  Responsive Helpers  */
.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 767.02px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1023.02px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1199.02px) {
  .hidden-lg {
    display: none !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUpAlt {
  from {
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.2;
  }
}
@keyframes fadeInUpAlt {
  from {
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.2;
  }
}
@-webkit-keyframes fadeInUpDev {
  from {
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.5;
  }
}
@keyframes fadeInUpDev {
  from {
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.5;
  }
}
@-webkit-keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */
.article {
  padding: 7rem 0;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .article {
    padding: 4rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article {
    padding: 2.3rem 0;
  }
}
.article .article__head {
  margin-bottom: 1.5rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .article .article__head {
    margin-bottom: 1.3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .article__head {
    margin-bottom: 1.9rem;
  }
}
.article .article__body {
  height: 40.2rem;
  margin-bottom: 7rem;
  position: relative;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .article .article__body {
    margin-right: 0.8rem;
    height: 45rem;
    margin-bottom: 4.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .article__body {
    height: auto;
    margin-bottom: 3.1rem;
    margin-right: 0;
  }
}
.article .btn {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .article .btn {
    min-width: 17.1rem;
  }
}
/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  padding: 1.1rem 2rem 1rem;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  min-width: 19.4rem;
  text-transform: uppercase;
  font-weight: 700;
}

.btn span {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn span {
    max-width: 13.9rem;
    padding-left: 1.3rem;
    line-height: 1.32;
  }
}
.btn .ico-play {
  display: inline-block;
  vertical-align: middle;
}

.btn--size1 {
  min-width: 33.7rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .btn--size1 {
    min-width: auto;
    padding: 1.3rem 5rem 1.7rem;
  }
}
.btn--size1 span {
  max-width: 100%;
  padding: 0;
}

.btn--disabled {
  opacity: 0.4;
}

.btn--alt {
  background-color: rgba(0, 0, 0, 0.5);
  color: #D8D8D8;
  border: 0.1rem solid #D8D8D8;
}

/*  Btn block  */
.btn--block {
  display: block;
  padding-left: 0;
  padding-right: 0;
}

.btn {
  position: relative;
  background-color: transparent;
  border-radius: 50em;
  border: none;
}

.btn:before {
  content: "";
  background: linear-gradient(130deg, #D2FF00, #F8E194 25%, #D6FBF0 60%, #1AD1D3) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
  color: #D2FF00;
  font-weight: 700;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  position: absolute;
  inset: 0;
}

.btn:hover {
  background: #d2ff00;
  color: #000000 !important;
  border: none;
  border-radius: 50em;
}

.btn:hover span,
.btn:hover {
  color: black;
}

.btn:hover:before {
  opacity: 0;
}

.btn span,
.btn {
  color: #D2FF00;
  font-weight: 700;
}

/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */
.checkbox {
  display: inline-block;
  text-align: left;
  position: relative;
}

.checkbox + .checkbox {
  margin-top: 1.6rem;
}

.checkbox label {
  position: relative;
  padding-left: 2.4rem;
  display: inline-block;
}

.checkbox label:before {
  content: "";
  width: 1.6rem;
  height: 1.6rem;
  background-color: #fff;
  border: 0.2rem solid #CCCCCC;
  position: absolute;
  left: 0;
  top: 0.3rem;
  border-radius: 0.5rem;
  z-index: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.checkbox label:after {
  content: "";
  width: 0.6rem;
  height: 1rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 0.2rem solid #fff;
  border-bottom: 0.2rem solid #fff;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1;
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.checkbox input:checked + label:before {
  background-color: #D2FF00;
  border-color: #D2FF00;
}

.checkbox input:checked + label:after {
  opacity: 1;
}

.checkbox input {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
}

/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */
.feature {
  text-align: center;
  position: relative;
  z-index: 2;
}

.feature span {
  margin-bottom: 2.7rem;
  display: block;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature span {
    margin-bottom: 0.8rem;
  }
}
.feature .feature__image {
  padding-top: 56.2%;
  background-size: cover;
  margin-bottom: 3rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .feature .feature__image {
    padding-top: 55.9%;
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature .feature__image {
    margin-bottom: 1.6rem;
  }
}
.feature .feature__title {
  margin-bottom: 0;
  line-height: 1.1;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature .feature__title {
    font-size: 3.2rem;
    margin-bottom: 0.9rem;
  }
}
/* ------------------------------------------------------------ *\
	Feature--alt
\* ------------------------------------------------------------ */
.feature--alt {
  padding: 1.9rem 0 0 1.9rem;
  margin-bottom: 4.6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .feature--alt {
    padding: 2.7rem 0 0;
    margin-bottom: 1.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature--alt {
    padding: 0;
    margin-bottom: 1.4rem;
  }
}
.feature--alt span {
  margin-bottom: 0.3rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .feature--alt span {
    margin-bottom: 2.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature--alt span {
    margin-bottom: 0.8rem;
  }
}
.feature--alt p {
  margin: 0 -0.5rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature--alt p {
    margin: 0;
  }
}
.feature--alt .feature__image {
  margin-bottom: 1.9rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .feature--alt .feature__image {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature--alt .feature__image {
    margin-bottom: 1.5rem;
  }
}
.feature--alt .feature__title {
  margin-bottom: 0.5rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .feature--alt .feature__title {
    font-size: 7.2rem;
    margin-bottom: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .feature--alt .feature__title {
    font-size: 3.2rem;
    margin-bottom: 0.9rem;
  }
}
/* ------------------------------------------------------------ *\
	Fields
\* ------------------------------------------------------------ */
.field {
  height: 4.8rem;
  background-color: #fff;
  border: none;
  border-radius: 0.5rem;
  opacity: 1;
  width: 100%;
  padding: 0 1.6rem;
  color: #000;
}

.field::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.field::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

.field:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.field::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.field::placeholder {
  color: rgba(0, 0, 0, 0);
}

.field--placeholder::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.field--placeholder::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.field--placeholder:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.field--placeholder::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.field--placeholder::placeholder {
  color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

/*  Field textarea  */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer {
  text-align: center;
  padding-bottom: 3rem;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 10;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .footer {
    padding-bottom: 0rem;
  }
}
.footer .footer__nav ul {
  margin: 0 0 0.5rem;
}

.footer .footer__nav li {
  display: inline-block;
  padding: 0 0.5rem;
}

.footer .footer__nav li + li {
  position: relative;
}

.footer .footer__nav li + li:before {
  content: "";
  width: 0.1rem;
  height: 50%;
  background-color: #fff;
  position: absolute;
  left: -0.2rem;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.footer .footer__nav a {
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  padding-right: 0.25rem;
}

.footer_rules {
  font-size: 1.4rem !important;
  color: #d2ff00;
  text-decoration: underline !important;
}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
.form .h3 {
  margin-bottom: 1.5rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .form .h3 {
    margin-bottom: 2.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .h3 {
    font-size: 4.6rem;
    line-height: 1.2;
    margin-bottom: 2.5rem;
  }
}
.form p {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .form p {
    margin-bottom: 3.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form p {
    margin-bottom: 0;
  }
}
.form .form__head {
  margin-bottom: 3.6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .form .form__head {
    text-align: center;
    margin-bottom: 3.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__head {
    margin-bottom: 2rem;
  }
}
.form .form__list {
  display: none;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .form .form__list {
    display: block;
  }
}
.form .form__label {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}

.form .form__label + .form__controls {
  margin-top: 1rem;
}

.form .form__field + .form__field {
  margin-top: 1.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__field + .form__field {
    margin-top: 0.8rem;
  }
}
.form .form__field + .form__checkboxes {
  margin-top: 1.8rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__field + .form__checkboxes {
    margin-top: 1.3rem;
  }
}
.form .form__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -1.4rem -0.8rem;
  /*  mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__row {
    display: block;
  }
}
.form .form__col {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding: 0 1.4rem 0.8rem;
  /*  mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__col {
    max-width: 100%;
  }
}
.form .form__body {
  margin-bottom: 2.8rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__body {
    margin-bottom: 3.3rem;
  }
}
.form .form__btn {
  min-width: 17rem;
  padding: 1.3rem 2rem 1.4rem;
}

.form .form__actions {
  text-align: center;
}

.form .form__actions--left {
  text-align: left;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__actions--left {
    text-align: center;
  }
}
.form .form__actions--left .form__btn {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__actions--left .form__btn {
    min-width: 100%;
  }
}
.form .form__slider {
  display: none;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .form .form__slider {
    display: block;
  }
}
/* ------------------------------------------------------------ *\
	Grid
\* ------------------------------------------------------------ */
.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -1rem -1.8rem;
  /*  Mobile  */
  /*  Grid col 1of2  */
  /*  Grid col 1of4  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid {
    display: block;
  }
}
.grid .grid__col {
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 0 1rem 1.8rem;
}

.grid .grid__col--1of2 {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid .grid__col--1of2 {
    max-width: 100%;
  }
}
.grid .grid__col--1of4 {
  max-width: 25%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid .grid__col--1of4 {
    max-width: 14.1rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.1rem;
    flex: 0 0 14.1rem;
  }
}
.grid--alt {
  margin: 0 -0.9rem -1.8rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .grid--alt {
    margin: 0 -0.7rem -1.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid--alt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.grid--alt .grid__col {
  padding: 0 0.9rem 1.8rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .grid--alt .grid__col {
    padding: 0 0.7rem 1.2rem;
  }
}
.grid--dev {
  margin: 0 -4.75rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .grid--dev {
    margin: 0 -3.3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid--dev {
    margin: 0 0 -5.9rem;
    display: block;
  }
}
.grid--dev .grid__col {
  padding: 0 4.75rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .grid--dev .grid__col {
    padding: 0 3.3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .grid--dev .grid__col {
    padding: 0 0 5.9rem;
    max-width: 100%;
  }
}
/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header p {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding-top: 0.4rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .header p {
    padding-top: 0;
  }
}
.header .header__inner {
  padding: 2.4rem 0 2.1rem;
  position: relative;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .header .header__inner {
    padding: 4.3rem 0 3.4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .header .header__inner {
    padding: 3.4rem 0 3.5rem;
  }
}
/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */
.hero {
  text-align: center;
  padding: 2.9rem 0;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .hero {
    padding: 0;
  }
}
.hero .h3 {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.hero p {
  font-weight: 600;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.hero .h3 + p {
  margin-top: 3.5rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero .h3 + p {
    padding: 0px 6rem;
    margin-top: 1.3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .h3 + p {
    padding: 0 1rem;
    margin-top: 1rem;
  }
}
.hero .hero__head .h3 {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.hero .hero__head p {
  font-size: 2rem;
  font-weight: 600;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__head p {
    font-size: 1.6rem;
  }
}
.hero .hero__head .h3 + p {
  margin-top: 3.4rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero .hero__head .h3 + p {
    margin-top: 1.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__head .h3 + p {
    margin-top: 1.8rem;
  }
}
.hero .hero__head {
  margin-bottom: 5.8rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero .hero__head {
    margin-bottom: 4.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero .hero__head {
    margin-bottom: 4.2rem;
  }
}
.hero .hero__video {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.hero .hero__video--alt {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-bottom: 3.3rem;
}

/* ------------------------------------------------------------ *\
	hero--alt
\* ------------------------------------------------------------ */
.hero--alt {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero--alt {
    padding-top: 5.4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--alt {
    padding: 0 0 8.1rem;
  }
}
.hero--alt .hero__head {
  display: none;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--alt .hero__head {
    display: block;
    margin-bottom: 3.4rem;
  }
}
.hero--alt .hero__head .h3 + p {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--alt .hero__head .h3 + p {
    margin-top: 1.4rem;
  }
}
.hero--alt .hero__foot {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--alt .hero__foot {
    display: none;
  }
}
.hero--alt .h3 {
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .hero--alt .h3 {
    font-size: 2.4rem;
  }
}
.hero--alt .hero__video {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero--alt .hero__video {
    margin-bottom: 4.6rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--alt .hero__video {
    padding-bottom: 10.8rem;
  }
}
/* ------------------------------------------------------------ *\
	Hero--type1
\* ------------------------------------------------------------ */
.hero--type1 {
  padding: 3.2rem 0 4.1rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero--type1 {
    padding: 0 0 4.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--type1 {
    padding: 0.2rem 0 3.8rem;
  }
}
.hero--type1 .hero__head {
  max-width: 61rem;
  width: 100%;
  margin: 0 auto;
}

.hero--type1 .hero__head .h3 {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero--type1 .hero__head .h3 {
    max-width: 50rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--type1 .hero__head .h3 {
    font-size: 3.2rem;
    line-height: 1.25;
  }
}
.hero--type1 .hero__head .h3 + p {
  margin-top: 1.5rem;
  /*  Tablet  */
  /*  Mobile  */
  margin-bottom: 2.5rem;
}

@media (max-width: 1023px) {
  .hero--type1 .hero__head .h3 + p {
    padding: 0;
    margin-top: 1.9rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--type1 .hero__head .h3 + p {
    margin-top: 1.4rem;
  }
}
.hero--type1 .hero__head--alt {
  max-width: 100%;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .hero--type1 .hero__head--alt {
    margin-bottom: 1.4rem;
  }
}
.hero--type1 .hero__head--alt .h3 {
  max-width: 100%;
}

.hero--type1 .hero__head--alt2 {
  margin-bottom: 0.7rem;
}

.hero--type1 .hero__body {
  background-size: 100%;
  /*cover;/*initial;*/
  background-repeat: no-repeat;
  background-position: top;
  /* 50% 2.2rem;*/
  padding-top: calc(680px * .564);
  padding-bottom: 2%;
  position: relative;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /* ⌄⌄⌄⌄⌄⌄⌄NEW⌄⌄⌄⌄⌄⌄⌄⌄ */
  width: 680px;
  margin: 0 auto;
  /* ^^^^^^^NEW^^^^^^^^ */
  /*  Tablet  */
  /*  Mobile  */
}

.hero__body .link-alt {
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 10px;
  text-shadow: -1px 1px 1px black, -1px 1px 3px black;
  text-underline-offset: 3px;
}

@media (max-width: 1023px) {
  .hero--type1 .hero__body {
    /* ⌄⌄⌄⌄⌄⌄⌄EDIT⌄⌄⌄⌄⌄⌄⌄⌄ */
    background-size: 680px auto;
    padding-top: calc(680px * 0.564);
    background-position: top;
    /* ^^^^^^^EDIT^^^^^^^^ */
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--type1 .hero__body {
    /* ⌄⌄⌄⌄⌄⌄⌄EDIT⌄⌄⌄⌄⌄⌄⌄⌄ */
    padding-top: calc(100% * .564);
    background-position: top;
    /* ^^^^^^^EDIT^^^^^^^^ */
    /* ⌄⌄⌄⌄⌄⌄⌄NEW⌄⌄⌄⌄⌄⌄⌄⌄ */
    width: 100%;
    background-size: 100% auto;
    /* ^^^^^^^NEW^^^^^^^^ */
  }
}
.hero--type1 .hero__body .btn {
  margin-bottom: 1rem;
}

.hero--type1 .hero__body--alt {
  padding-bottom: 2.8rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero--type1 .hero__body--alt {
    padding-top: 32.8%;
    padding-bottom: 1.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--type1 .hero__body--alt {
    padding-top: 60.1%;
  }
}
.hero--type1-alt {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .hero--type1-alt {
    padding-bottom: 3.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .hero--type1-alt {
    padding-bottom: 2.1rem;
  }
}
/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */
[class*=ico-] {
  display: inline-block;
  vertical-align: middle;
}

[class*=ico-] img, [class*=ico-] svg {
  width: 100%;
  height: 100%;
  display: block;
}

.ico-play {
  width: 1.7rem;
  height: 2rem;
}

.ico-check {
  width: 1.7rem;
  height: 1.1rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .ico-check {
    width: 1.66rem;
    height: 0.9rem;
  }
}
/* ------------------------------------------------------------ *\
	Link-alt
\* ------------------------------------------------------------ */
.link-alt {
  font-size: 1.6rem;
}

/* ------------------------------------------------------------ *\
	Link
\* ------------------------------------------------------------ */
.link {
  font-size: 1.2rem;
  color: #ffffff;
}

.link:hover {
  color: #D2FF00;
}

/* ------------------------------------------------------------ *\
	List-def
\* ------------------------------------------------------------ */
.list-def {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  position: relative;
  height: 1rem;
  overflow: hidden;
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-def {
    padding: 0 0.5rem;
  }
}
.list-def:before {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 8;
}

.list-def:last-child {
  position: relative;
  z-index: 10;
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
}

.list-def:last-child:after {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 8;
}

.list-def li:last-child {
  z-index: 0;
}

.list-def li:last-child:after {
  content: "";
  width: 999rem;
  height: 0.1rem;
  background-color: #6F6E6E;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.list-def li {
  list-style-type: none;
  position: relative;
  display: inline-block;
  z-index: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.list-def li:before {
  content: "";
  width: 0.4rem;
  height: 0.4rem;
  background-color: #6F6E6E;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

/*.list-def.list-current li:first-child {
    z-index: 2; }
.list-def.list-current li:first-child:after {
      content: '';
      width: 999rem;
      height: .3rem;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 60%;
      left: auto;
      -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
              transform: translate(0, -50%);
      z-index: 0; }*/
.list-def .passed {
  z-index: 4;
}

.list-def .passed:after {
  content: "";
  width: 999rem !important;
  height: 0.3rem !important;
  background-color: #fff !important;
  position: absolute !important;
  top: 50% !important;
  left: auto !important;
  right: -45% !important;
  -webkit-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  z-index: 15;
}

.list-def .passed + li:before, .list-def .passed:before {
  opacity: 0;
}

.list-def.list-passed + .list-def {
  left: -0.1rem;
}

/* ------------------------------------------------------------ *\
	List-dev
\* ------------------------------------------------------------ */
.list-dev {
  height: 0.8rem;
  border: 0.1rem solid #4B4B4B;
  border-radius: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.list-dev li {
  /*// max-width: 20%;
  // -webkit-box-flex: 0;
  //     -ms-flex: 0 0 20%;
  //         flex: 0 0 20%;
   */
  font-size: 0;
  display: inline-block;
  height: 100%;
}

.list-dev li + li {
  border-left: 0.1rem solid #fff;
}

.list-dev .current {
  background-color: #fff;
}

.list-dev li + .current {
  border-color: #000;
}

/* ------------------------------------------------------------ *\
	List-products
\* ------------------------------------------------------------ */
.list-products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0 -0.5rem;
  padding-left: 2rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .list-products {
    padding-left: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-products {
    margin: 0;
    display: block;
  }
}
.list-products li {
  display: inline-block;
  max-width: 18.8rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18.8rem;
  flex: 0 0 18.8rem;
  margin: 0 0.5rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .list-products li {
    margin: 0 7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .list-products li {
    margin: 0;
  }
}
.list-products li + li {
  max-width: 17rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17rem;
  flex: 0 0 17rem;
}

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
.logo {
  display: inline-block;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .logo {
    width: 10.4rem;
    height: 3.3rem;
  }
}
/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */
.popup {
  z-index: 999999;
  position: absolute;
  top: 0;
  left: -9999rem;
  width: 100%;
  height: 100%;
  padding: 4rem;
  opacity: 0;
  -webkit-transition: all 0s 0.4s opacity 0.4s;
  -o-transition: all 0s 0.4s opacity 0.4s;
  transition: all 0s 0.4s opacity 0.4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .popup {
    padding: 4rem 0.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup {
    padding: 4rem 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.popup .popup__inner {
  padding: 2.1rem 2.9rem 3rem 3.4rem;
  background-color: rgba(31, 31, 31, 0.8);
  text-align: left;
  max-width: 74.9rem;
  width: 100%;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .popup .popup__inner {
    position: relative;
    padding: 6.6rem 2.7rem 5.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup .popup__inner {
    padding: 5.3rem 2.5rem 6.2rem;
    height: calc(100vh - 8rem);
    overflow-y: auto;
  }
}
.popup .popup__head {
  position: relative;
  padding-right: 3rem;
  margin-bottom: 1.9rem;
  /*  tablet  */
}

@media (max-width: 1023px) {
  .popup .popup__head {
    position: static;
    padding-right: 0;
  }
}
.popup .popup__title {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup .popup__title {
    font-size: 3.2rem;
    margin-top: 1rem;
  }
}
.popup .popup__btn {
  position: absolute;
  top: 0.2rem;
  right: -0.5rem;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .popup .popup__btn {
    top: 2.3rem;
    right: 2.4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup .popup__btn {
    top: 1.9rem;
    right: 1.4rem;
  }
}
.popup .popup__btn:hover {
  opacity: 0.7;
}

@media (max-width: 650px) {
  .popup .popup__btn img {
    width: 3rem;
    height: 3rem;
  }
}
.popup .popup__body {
  height: 82%;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .popup .popup__body {
    margin-bottom: 3.4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup .popup__body {
    height: auto;
  }
}
.popup .popup__body p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.popup .popup__actions {
  display: none;
  text-align: center;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .popup .popup__actions {
    display: block;
  }
}
.popup--size1 {
  max-height: 50rem;
}

.popup--open {
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

/* ------------------------------------------------------------ *\
	Popup--alt
\* ------------------------------------------------------------ */
.popup--alt {
  background-color: rgba(255, 255, 255, 0.4);
}

.popup--alt .popup__inner {
  text-align: center;
  background-color: #000;
  padding: 3rem 2.9rem 1.9rem 3.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup--alt .popup__inner {
    padding: 5.9rem 2.3rem 5.6rem;
    height: 54.1rem;
  }
}
.popup--alt .popup__image {
  padding-top: 39.5%;
  background-size: cover;
  max-width: 48.2rem;
  margin: 0 auto 2.5rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup--alt .popup__image {
    padding-top: 56.5%;
    margin: 0.5px auto 3.2rem;
  }
}
.popup--alt .popup__actions {
  display: block;
  max-width: 48.2rem;
  margin: 0 auto;
}

.popup--alt .popup__head {
  padding-right: 0;
  max-width: 48.2rem;
  margin: 0 auto 2.5rem;
}

.popup--alt .popup__head p {
  margin: 0 auto;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup--alt .popup__head p {
    padding: 0 0.5rem;
  }
}
.popup--alt .popup__title {
  margin-bottom: 0.2rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup--alt .popup__title {
    margin-top: 0.8rem;
    margin-bottom: 0;
  }
}
.popup--alt .btn {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .popup--alt .btn {
    min-width: 100%;
  }
}
/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */
.product {
  text-align: center;
  position: relative;
}

.product .h6 {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.43rem;
  margin-bottom: 2rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .product .h6 {
    margin-bottom: 1.8rem;
  }
}
.product figure {
  text-align: center;
  height: 20rem;
}

.product p {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.product .product__image {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .product .product__image {
    width: 22.5rem;
    height: auto;
  }
}
.product .product__image--size1 {
  width: 13rem;
  height: 13rem;
  display: inline-block;
}

.product .product__image--size2 {
  width: 10.6rem;
  height: 10.6rem;
  display: inline-block;
}

.product .product__info {
  position: absolute;
  left: -9999rem;
  top: 0;
  width: 100%;
  height: 100%;
  /*background-color: #1F1F1F;*/
  text-align: left;
  padding: 4.4rem 2.5rem;
  font-size: 1.2rem;
  opacity: 0;
  -webkit-transition: opacity 0.4s, left 0s 0.4s;
  -o-transition: opacity 0.4s, left 0s 0.4s;
  transition: opacity 0.4s, left 0s 0.4s;
  background-color: #082125 !important;
  /* 
  background-image: radial-gradient(transparent 30%, #ffffff12 160%);
     box-shadow: inset 0px 0px 15px -6px rgb(255 255 255 / 7%);
  */
  /* ⌄⌄⌄⌄⌄⌄⌄NEW⌄⌄⌄⌄⌄⌄⌄⌄ */
  border-radius: 5px;
  border: solid 1px #d1d3d4;
  z-index: 1;
  /* ^^^^^^^NEW^^^^^^^^ */
}

.product .product__info p {
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.product .product__info p + p {
  margin-top: 1.4rem;
}

.product .product__info strong {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.product .product__close {
  position: absolute;
  right: 1.9rem;
  top: 0.9rem;
}

.product .more-loaded .product__info {
  -webkit-transition: opacity 0.4s, left 0s;
  -o-transition: opacity 0.4s, left 0s;
  transition: opacity 0.4s, left 0s;
  left: 0;
  opacity: 1;
}

.more__loaded {
  -webkit-transition: opacity 0.4s, left 0s !important;
  transition: opacity 0.4s, left 0s !important;
  left: 0 !important;
  opacity: 1 !important;
}

/* ------------------------------------------------------------ *\
	Product--alt
\* ------------------------------------------------------------ */
.product--alt {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .product--alt {
    text-align: center;
  }
}
.product--alt .h6 {
  margin-bottom: 1.8rem;
  min-height: 3.2rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .product--alt .h6 {
    min-height: auto;
  }
}
.product--alt figure {
  height: 11.6rem;
  margin-bottom: 2.9rem;
}

/* ------------------------------------------------------------ *\
	Question
\* ------------------------------------------------------------ */
.question + .question {
  margin-top: 4.2rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .question + .question {
    margin-top: 7.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question + .question {
    margin-top: 5rem;
  }
}
.question + .question:nth-child(2) {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .question + .question:nth-child(2) {
    margin-top: 6.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question + .question:nth-child(2) {
    margin-top: 4.7rem;
  }
}
.question .question__title {
  letter-spacing: 0.62rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 2.2rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question .question__title {
    font-size: 1.6rem;
    letter-spacing: 0.5rem;
    margin-bottom: 1.4rem;
  }
}
.question .question__title--alt {
  margin-bottom: 1.3rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question .question__title--alt {
    margin-bottom: 0.8rem;
  }
}
.question .question__head {
  margin-bottom: 1.7rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question .question__head {
    margin-bottom: 2.4rem;
  }
}
.question .question__head--alt {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .question .question__head--alt {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question .question__head--alt {
    margin-bottom: 2.2rem;
  }
}
.question .question__head--alt .question__title {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .question .question__head--alt .question__title {
    margin-bottom: 1.6rem;
  }
}
/* ------------------------------------------------------------ *\
	Radio
\* ------------------------------------------------------------ */
.radio {
  display: inline-block;
  width: 100%;
  position: relative;
}

.radio label {
  width: 100%;
  display: block;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid #ffffff;
  text-align: center;
  padding: 1.1rem 2rem 1rem;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .radio label {
    padding: 1.1rem 1rem 1rem;
  }
}
.radio label:hover {
  /*  Tablet  */
}

@media (min-width: 1023.02px) {
  .radio label:hover {
    border-color: #D2FF00;
    background: #D2FF00;
    color: #000000;
  }
}
.radio input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.radio input:checked + label {
  border-color: #D2FF00;
  background: #D2FF00;
  color: #000000;
}

/* Make all checked button labels red... */
.radio input:checked ~ label {
  border-color: #D2FF00;
  background: #D2FF00;
  color: #000000;
}

/* ...except for the desktop label. */
.radio input:checked + label {
  border: 0.1rem solid rgba(255, 255, 255, 0.25);
  background-color: transparent;
}

/* ------------------------------------------------------------ *\
	Section-alt
\* ------------------------------------------------------------ */
.section-alt {
  text-align: center;
  padding: 22.8rem 0;
  /* ⌄⌄⌄⌄⌄⌄⌄NEW⌄⌄⌄⌄⌄⌄⌄⌄ */
  z-index: 1;
  position: relative;
  /* ^^^^^^^NEW^^^^^^^^ */
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-alt {
    padding: 25.8rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt {
    padding: 4.7rem 0 4.2rem;
  }
}
.section-alt .h2 {
  margin-bottom: 3.2rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt .h2 {
    margin-bottom: 2.5rem;
  }
}
.section-alt .h5 {
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 3.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt .h5 {
    line-height: 1.5;
    margin-bottom: 4.1rem;
  }
}
.section-alt p {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt p {
    padding: 0 1rem;
  }
}
.section-alt .section__actions {
  padding-top: 2rem;
  margin: 0 -0.5rem -1.7rem;
}

.section-alt .btn {
  margin: 0 0.5rem 1.7rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt .btn {
    min-width: 27.2rem;
  }
}
/* ------------------------------------------------------------ *\
	Section-alt--dev
\* ------------------------------------------------------------ */
.section-alt--dev {
  padding: 11.3rem 0;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-alt--dev {
    padding: 8.1rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt--dev {
    padding: 2.1rem 0 6rem;
  }
}
.section-alt--dev .section__actions {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-alt--dev .section__actions {
    padding-top: 0.7rem;
  }
}
/* ------------------------------------------------------------ *\
	Section-def
\* ------------------------------------------------------------ */
.section-def {
  padding: 1.5rem 0;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def {
    padding: 0.2rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def {
    padding-bottom: 1rem;
  }
}
.section-def .section__head {
  margin-bottom: 2.4rem;
}

.section-def .section__head--alt {
  margin-bottom: 6rem;
}

.section-def .h5 {
  margin-bottom: 4rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-bottom: 1.3rem;
}

.section-def .h5 span {
  color: #D8D8D8;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding-left: 1rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-def .h5 span {
    padding-left: 0;
  }
}
.section-def .section__head-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4rem;
  overflow: hidden;
  max-width: 75rem;
  margin: 0 auto;
}

.section-def .section__title {
  max-width: 45rem;
  margin: 0 auto 3.1rem;
  text-align: center;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-def .section__title {
    margin-bottom: 3.6rem;
  }
}
.section-def .section__body {
  margin-bottom: 2.6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def .section__body {
    margin-bottom: 12.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def .section__body {
    margin-bottom: 3.4rem;
  }
}
.section-def .section__content {
  padding: 0 2.4rem;
  position: relative;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-def .section__content {
    padding: 0;
  }
}
.section-def .section__content:after {
  content: "";
  width: 28.8rem;
  height: 16.2rem;
  background-image: url("assets/images/svg/starz-bracket-vs.svg");
  position: absolute;
  bottom: 6.6rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background-size: cover;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def .section__content:after {
    bottom: 6.3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def .section__content:after {
    width: 14.9rem;
    height: 8.4rem;
    bottom: auto;
    top: 44.9%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: 14.9rem 8.4rem;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.section-def .section__actions {
  text-align: center;
}

.section-def .section__actions ul li {
  display: inline-block;
}

.section-def .section__actions ul p {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.section-def .section__actions ul p span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #D8D8D8;
}

.section-def .section__actions ul li:nth-child(2) {
  margin: 0 0.6rem;
}

.section-def .section__actions ul a {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.section-def .section__actions ul a:hover {
  opacity: 1;
  /* -webkit-tap-highlight-color: white; */
}

.section-def .section__btn {
  margin-right: 1.5rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def .section__btn {
    margin-right: 0;
    min-width: 100%;
    margin-bottom: 1.8rem;
  }
}
.section-def .section__btn:last-child {
  margin-right: 0;
}

/* ------------------------------------------------------------ *\
	Section-def--alt
\* ------------------------------------------------------------ */
.section-def--alt .section__head {
  margin-bottom: 16.6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def--alt .section__head {
    margin-bottom: 18.8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--alt .section__head {
    margin-bottom: 8.7rem;
  }
}
.section-def--alt .section__title {
  margin-bottom: 0;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--alt .section__title {
    font-size: 5.6rem;
  }
}
.section-def--alt .section__body {
  margin-bottom: 0.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--alt .section__body {
    margin-bottom: 2.1rem;
  }
}
/* ------------------------------------------------------------ *\
	Section-def--dev
\* ------------------------------------------------------------ */
.section-def--dev {
  padding: 1.7rem 0;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def--dev {
    padding: 7.1rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--dev {
    padding: 0.1rem 0;
  }
}
.section-def--dev .section__inner {
  padding: 0 2.3rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-def--dev .section__inner {
    padding: 0;
  }
}
.section-def--dev .section__actions {
  padding-top: 3rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--dev .section__actions {
    padding-top: 2rem;
  }
}
/* ------------------------------------------------------------ *\
	section-def--base
\* ------------------------------------------------------------ */
.section-def--base {
  padding-bottom: 2.7rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def--base {
    padding: 0.1rem 0 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--base {
    padding-bottom: 0.3rem;
  }
}
.section-def--base .section__content:after {
  display: none;
}

.section-def--base .section__head {
  margin-bottom: 4.9rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--base .section__head {
    margin-bottom: 1.5rem;
  }
}
.section-def--base .section__title {
  max-width: 100%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--base .section__title {
    font-size: 3.2rem;
    margin-bottom: 1.8rem;
  }
}
.section-def--base .section__body {
  margin-bottom: 26.2vh;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-def--base .section__body {
    margin-bottom: 36vh;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--base .section__body {
    margin-bottom: 4.5rem;
  }
}
.section-def--base .section__foot {
  max-width: 64rem;
  margin: 0 auto;
  text-align: center;
}

.section-def--base .section__foot p {
  margin-bottom: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.section-def--base .section__foot p span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #D8D8D8;
  margin-left: 0.4rem;
  margin-right: -0.4rem;
}

.section-def--base .grid .grid__col + .grid__col {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-def--base .grid .grid__col + .grid__col {
    margin-top: 2rem;
  }
}
/* ------------------------------------------------------------ *\
	Section-dev
\* ------------------------------------------------------------ */
.section-dev {
  padding: 3.2rem 0 3.8rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-dev {
    padding: 2rem 0 4.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-dev {
    padding: 0.3rem 0 4rem;
  }
}
.section-dev .section__title {
  text-align: center;
  margin-bottom: 5.5rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-dev .section__title {
    font-size: 3.2rem;
    line-height: 1.25;
    margin-bottom: 3.6rem;
  }
}
.section-dev .section__inner {
  padding: 0 2.5rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-dev .section__inner {
    padding: 0;
  }
}
.section-dev .section__foot {
  text-align: center;
}

.section-dev .section__foot .section__actions {
  margin-bottom: 1rem;
}

.section-dev .section__foot .section__actions .btn {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-dev .section__foot .section__actions .btn {
    min-width: 100%;
  }
}
.section-dev .section__foot p {
  color: #D2FF00;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0.045rem;
}

.section-dev .section__body {
  margin-bottom: 4rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-dev .section__body {
    margin-bottom: 4.3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-dev .section__body {
    margin-bottom: 4.7rem;
  }
}
.section-dev.questions-filled .section__foot p {
  display: none;
}

/* ------------------------------------------------------------ *\
	Section-form 
\* ------------------------------------------------------------ */
.section-form {
  position: relative;
  z-index: 2;
  padding: 5.5rem 0;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section-form {
    padding: 0 0 6rem;
    margin-top: -2.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form {
    padding: 2rem 0;
    margin-top: 0;
  }
}
.section-form .section__widget {
  display: inline-block;
  width: 38.3rem;
  margin-right: 4.3rem;
  vertical-align: top;
  text-align: center;
  /*  Small Desktop  */
  /*  Tablet  */
}

@media (max-width: 1199px) {
  .section-form .section__widget {
    margin-right: 3.5rem;
  }
}
@media (max-width: 1023px) {
  .section-form .section__widget {
    display: block;
    width: 100%;
  }
}
.section-form .section__form {
  display: inline-block;
  width: 57rem;
  vertical-align: top;
  padding-top: 1.2rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-form .section__form {
    display: block;
    width: 100%;
    padding-top: 2.5rem;
  }
}
.section-form .section__inner {
  max-width: 102.7rem;
  width: 100%;
  margin: 0 auto;
}

.section-form .section__list {
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section-form .section__list {
    display: none;
  }
}
/* ------------------------------------------------------------ *\
	Section-form--alt
\* ------------------------------------------------------------ */
.section-form--alt {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form--alt {
    padding-top: 0.2rem;
  }
}
.section-form--alt .form .h3 {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section-form--alt .form .h3 {
    font-size: 3.2rem;
  }
}
/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
.section {
  padding: 1.4rem 0;
  position: relative;
  z-index: 1;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section {
    padding: 0.9rem 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section {
    padding: 0.3rem 0;
  }
}
.section .h5 {
  text-transform: uppercase;
  letter-spacing: 0.49rem;
  margin-bottom: 3rem;
  /*  Mobile  */
}

.section .h5 {
  background: #D2ff00;
  color: black;
  font-weight: 600;
  width: fit-content;
  line-height: 1;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 0;
  text-transform: uppercase;
  top: 0;
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .h5 {
    margin-bottom: 1.8rem;
  }
}
.section .h1 {
  text-align: center;
  line-height: 1.05;
  margin-bottom: 1.5rem;
}

.section figure {
  padding-top: 46.4%;
  background-size: cover;
  margin-bottom: 2.3rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section figure {
    margin-bottom: 5.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section figure {
    margin: 0 -2.5rem 2.7rem;
    padding-top: 68%;
  }
}
.section p {
  text-align: center;
  margin-bottom: 0;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section p {
    padding: 0 0.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section p {
    padding: 0;
  }
}
.section .section__inner {
  padding: 0 2.3rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section .section__inner {
    padding: 0;
    text-align: center;
  }
}
.section .section__head {
  margin-bottom: 3.6rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section .section__head {
    margin-bottom: 3.1rem;
  }
}
.section .section__head-list {
  display: none;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section .section__head-list {
    display: block;
    padding: 0 2.8rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__head-list {
    padding: 0;
    margin-bottom: 1.7rem;
  }
}
.section .section__head--alt {
  margin-bottom: 6.7rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .section .section__head--alt {
    margin-bottom: 5.4rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__head--alt {
    margin-bottom: 2.5rem;
  }
}
.section .section__head--alt figure {
  margin-bottom: 0;
}

.section .section__body {
  margin-bottom: 6.7rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__body {
    padding-bottom: 2.8rem;
    border-bottom: 0.2rem solid #979797;
    margin-bottom: 2.5rem;
  }
}
.section .section__body--alt {
  margin-bottom: 2.8rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__body--alt {
    border-bottom: none;
    margin-bottom: 3.4rem;
  }
}
.section .section__actions {
  display: none;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .section .section__actions {
    display: block;
    padding-bottom: 2rem;
  }
}
.section .section__actions .section__btn {
  width: 100%;
}

.section .section__foot {
  padding: 0 5.2rem;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .section .section__foot {
    display: none;
  }
}
.section .section__button {
  text-align: center;
  padding-top: 3rem;
}

.stage {
  opacity: 0;
  -webkit-transition: left 0 0.4s opacity 0.4s;
  -o-transition: left 0 0.4s opacity 0.4s;
  transition: left 0 0.4s opacity 0.4s;
}

.currentstage {
  opacity: 1;
}

/* ------------------------------------------------------------ *\
	Select
\* ------------------------------------------------------------ */
.select {
  position: relative;
}

.select select::-ms-expand {
  display: none;
}

.select:after {
  content: "";
  width: 1rem;
  height: 0.6rem;
  background-image: url("assets/images/svg/triangle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 1.6rem;
  pointer-events: none;
}

/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: calc( 796px + 15px * 2);
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .shell {
    padding-left: 6.1rem;
    padding-right: 6.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .shell {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.shell--large {
  max-width: calc( 1170px + 15px * 2);
}

.shell--medium {
  max-width: calc( 873px + 15px * 2);
}

/* Shell Fluid */
.shell--fluid {
  max-width: none;
}

/* ------------------------------------------------------------ *\
	Simple scroll
\* ------------------------------------------------------------ */
.simplebar {
  position: relative;
}

.simplebar .simplebar-content {
  padding-right: 7.1rem !important;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .simplebar .simplebar-content {
    padding-right: 5.2rem !important;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .simplebar .simplebar-content {
    padding-right: 0 !important;
  }
}
.simplebar .simplebar-scrollbar {
  background: #fff !important;
  opacity: 1;
  width: 0.7rem;
  border-radius: 0.5rem;
}

.simplebar .simplebar-scrollbar:before {
  display: none;
}

.simplebar .simplebar-track {
  width: 1rem !important;
  border-radius: 0.5rem;
  border: 0.1rem solid #4B4B4B;
}

/* ------------------------------------------------------------ *\
	Simplebar
\* ------------------------------------------------------------ */
.simplebar .simplebar-content {
  padding-right: 1.3rem !important;
}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
.slider {
  padding: 1.6rem 0 2.7rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .slider {
    padding: 4.6rem 0 3rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider {
    padding: 3.1rem 0 2.6rem;
  }
}
.slider .h4 {
  display: inline-block;
  position: relative;
  padding: 0 1rem 0.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .h4 {
    max-width: 16.5rem;
    padding: 0;
  }
}
.slider .h4:before, .slider .h4:after {
  content: "";
  width: 100vw;
  height: 0.15rem;
  background-color: #979797;
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .h4:before, .slider .h4:after {
    top: 1.4rem;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.slider .h4:before {
  left: auto;
  right: 100%;
}

.slider .slider__head {
  text-align: center;
  overflow-x: hidden;
  margin-bottom: 3.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .slider__head {
    margin-bottom: 2.8rem;
  }
}
.slider .slider__head--alt .h4 {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .slider__head--alt .h4 {
    max-width: 19.5rem;
  }
}
.slider .slider__clip {
  position: relative;
  padding: 0 2.5rem 4.6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .slider .slider__clip {
    padding: 0 4.9rem 4.5rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .slider__clip {
    padding: 0 1.9rem 4.5rem;
  }
}
.slider .slider__pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.slider .swiper-pagination-bullet {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: transparent;
  border: 0.1rem solid #fff;
  opacity: 1;
}

.slider .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.5rem;
}

.slider .swiper-pagination-bullet-active {
  background-color: #fff;
}

.slider .swiper-button-next {
  position: absolute;
  right: 0.5rem;
  top: 46%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-image: url("assets/images/svg/arrow-right.svg");
  width: 0.8rem;
  height: 1.3rem;
  background-size: 100%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .swiper-button-next {
    top: 49.3%;
    right: 0;
  }
}
.slider .swiper-button-prev {
  position: absolute;
  left: 0.5rem;
  top: 46%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-image: url("assets/images/svg/arrow-left.svg");
  width: 0.8rem;
  height: 1.3rem;
  background-size: 100%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider .swiper-button-prev {
    top: 49.3%;
    left: 0;
  }
}
/* ------------------------------------------------------------ *\
	Slider--alt
\* ------------------------------------------------------------ */
.slider--alt {
  /*  Mobile  */
  /*  Mobile  */
}

@media (min-width: 767.02px) {
  .slider--alt {
    padding: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider--alt {
    padding-bottom: 0;
  }
}
.slider--alt .slider__clip {
  /*  Mobile  */
  /*  Mobile  */
}

@media (min-width: 767.02px) {
  .slider--alt .slider__clip {
    padding: 0;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .slider--alt .slider__clip {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.slider--alt .slider__pagination, .slider--alt .swiper-button-prev, .slider--alt .swiper-button-next {
  /*  Mobile  */
}

@media (min-width: 767.02px) {
  .slider--alt .slider__pagination, .slider--alt .swiper-button-prev, .slider--alt .swiper-button-next {
    display: none;
  }
}
.slider--alt .slider__slides {
  /*  Mobile  */
}

@media (min-width: 767.02px) {
  .slider--alt .slider__slides {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.slider--alt .slider__slide {
  /*  Mobile  */
}

@media (min-width: 767.02px) {
  .slider--alt .slider__slide {
    max-width: 18.8rem;
    display: inline-block;
    margin: 0 7rem;
  }
}
.slider--alt .slider__slide + .slider__slide {
  /*  Mobile  */
}

@media (min-width: 767.02px) {
  .slider--alt .slider__slide + .slider__slide {
    max-width: 17rem;
  }
}
/* ------------------------------------------------------------ *\
	Tile-alt
\* ------------------------------------------------------------ */
.tile-alt {
  text-align: center;
  position: relative;
}

.tile-alt .tile__image {
  padding-top: 56.2%;
  background-size: cover;
  margin-bottom: 1.9rem;
  position: relative;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile-alt .tile__image {
    margin-bottom: 1.3rem;
  }
}
.tile-alt .tile__btn {
  position: absolute;
  bottom: 0.3rem;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}

.tile-alt .tile__title {
  margin-bottom: 0.6rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile-alt .tile__title {
    font-size: 2.2rem;
    margin-bottom: 0.4rem;
  }
}
.tile-alt .tile__subtitle {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  color: #D8D8D8;
  margin-bottom: 0;
}

.tile-alt.selected .tile__image {
  position: relative;
}

.tile-alt.selected .tile__image:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 0.5rem solid #fff;
  z-index: 4;
  pointer-events: none;
}

.tile-alt.selected .tile__btn {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile-alt.selected .tile__btn {
    opacity: 1;
    visibility: visible;
  }
}
/* ------------------------------------------------------------ *\
	Tile-alt--dev
\* ------------------------------------------------------------ */
.tile-alt--dev .tile__image {
  padding-top: 58.4%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile-alt--dev .tile__image {
    padding-top: 56.5%;
  }
}
.tile-alt--dev.selected .tile__image {
  -webkit-box-shadow: inset 0 0 0 0.5rem #fff;
  box-shadow: inset 0 0 0 0.5rem #fff;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile-alt--dev.selected .tile__image {
    padding-top: 58.1%;
  }
}
/* ------------------------------------------------------------ *\
	Tile
\* ------------------------------------------------------------ */
.tile {
  position: relative;
  height: 100%;
}

.tile .tile__body {
  min-height: 17.5rem;
  height: calc(100% - 2.6rem);
  background-color: #4B4B4B;
  color: #fff;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  padding: 1.4rem 1.1rem 1rem;
  text-align: center;
  line-height: 1.3;
  position: relative;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .tile .tile__body {
    font-size: 2rem;
    min-height: 15rem;
    height: calc(100% - 2rem);
    padding: 1.2rem 1.2rem 1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile .tile__body {
    min-height: 12.7rem;
    height: calc(100% - 2rem);
    font-size: 1.6rem;
    padding: 0.9rem 1rem 1rem;
    line-height: 1.5;
  }
}
.tile .tile__radio {
  text-align: center;
  margin-top: -0.1rem;
  position: relative;
  z-index: 1;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .tile .tile__radio {
    height: 2rem;
  }
}
.tile .tile__radio input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tile .tile__radio label {
  border: 0.1rem solid #434343;
  width: 100%;
  height: 100%;
  display: block;
}

.tile .tile__radio label i {
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  vertical-align: middle;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .tile .tile__radio label i {
    margin-bottom: 0.7rem;
    margin-left: 0.8rem;
  }
}
.tile .tile__radio input:checked + label {
  opacity: 1;
  background-color: #D2FF00;
  border-color: #D2FF00;
}

.tile .tile__radio input:checked + label i {
  opacity: 1;
}

.tile .tile__image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}

.tile .tile__label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 1.4rem;
  color: #000000;
  background-color: #D2FF00;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  padding: 0.3rem 0.5rem 0.1rem;
  line-height: 1.2;
}

.tile.tile--confirm .tile__label {
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .tile.tile--confirm .tile__label {
    opacity: 1;
    visibility: visible;
  }
}
/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */
.video {
  position: relative;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .video {
    padding-bottom: 10.2rem;
  }
}
.video .video__inner {
  position: relative;
  padding-top: 55.9%;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 0.2rem #979797;
  box-shadow: 0 0 0 0.2rem #979797;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .video .video__inner {
    padding-top: 55.6%;
  }
}
.video:hover .video__poster {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(1.05) translate(-50%, -50%);
  -ms-transform: scale(1.05) translate(-50%, -50%);
  transform: scale(1.05) translate(-50%, -50%);
}

.video .video__poster, .video .video__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 101%;
  min-height: 101%;
  background-size: cover;
  z-index: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.video .video__content {
  z-index: 3;
  left: -9999rem;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.video .video__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 1.1rem 1rem;
  z-index: 2;
  min-width: 33.7rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .video .video__btn {
    min-width: 21.7rem;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 0;
    padding: 1.5rem 2.7rem;
    text-align: left;
  }
}
.video .video__btn .ico-play {
  margin-right: 1rem;
  margin-bottom: 0.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .video .video__btn .ico-play {
    margin-right: 0;
    margin-bottom: 0.8rem;
    vertical-align: middle;
  }
}
.video.is-playing .video__content {
  left: 50%;
  opacity: 1;
}

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */
.widget {
  width: 40.6rem;
  height: 40.6rem;
  position: relative;
  display: inline-block;
  text-align: center;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .widget {
    width: 30rem;
    height: 30rem;
    text-align: center;
  }
}
@media (max-width: 330px) {
  .widget {
    margin-left: -1.5rem;
  }
}
.widget .h5 {
  margin-bottom: 1.7rem;
  letter-spacing: 0.54rem;
  text-transform: uppercase;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .widget .h5 {
    font-size: 1.2rem;
    letter-spacing: 0.32rem;
    margin-bottom: 1.4rem;
  }
}
.widget .h1 {
  font-size: 12rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.5rem;
  line-height: 1.1;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .widget .h1 {
    font-size: 8.8rem;
  }
}
.widget p {
  margin-bottom: 0;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .widget p {
    font-size: 1.2rem;
  }
}
.widget span {
  display: inline-block;
}

.widget .widget__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .widget .widget__content {
    padding-bottom: 1rem;
  }
}
.widget .circular-chart {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.widget .circle {
  stroke: #D2ff00;
  fill: none;
  stroke-width: 0.2;
  -webkit-animation: progress 1s ease-out forwards;
  animation: progress 1s ease-out forwards;
}

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  position: relative;
  min-height: 100vh;
  background-color: #000;
  overflow: hidden;
  padding-bottom: 6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .wrapper {
    padding-bottom: 8rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .wrapper {
    padding-bottom: 10rem;
  }
}
.wrapper .wrapper__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  opacity: 1;
  pointer-events: none;
  z-index: 0;
  -webkit-animation-name: fadeInUpAlt;
  animation-name: fadeInUpAlt;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  /*  Tablet  */
  background-color: #082125;
}

@media (max-width: 1023px) {
  .wrapper .wrapper__background:not(.wrapper__background--dev) {
    /* background-image: url("assets/images/temp/bg-toplong.png") !important;
          background-size: 100% auto;
    */
  }
}
.wrapper .wrapper__background--dev {
  -webkit-animation-name: fadeInUpDev;
  animation-name: fadeInUpDev;
  position: fixed;
  height: 100vh;
  background-color: #deb14d;
}

@media (max-width: 1023px) {
  .wrapper .wrapper__background--dev {
    background-image: url("assets/images/temp/bg-sides.png") !important;
    background-size: 100% auto;
    background-position: 50% 12%;
  }
}
/* ------------------------------------------------------------ *\
	Overwrites
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Simple scroll
\* ------------------------------------------------------------ */
.simplebar {
  position: relative;
}

.simplebar .simplebar-content {
  padding-right: 9rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .simplebar .simplebar-content {
    padding-right: 5.2rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .simplebar .simplebar-content {
    padding-right: 1rem;
  }
}
.simplebar .simplebar-scrollbar {
  background: #fff;
  opacity: 1;
  width: 0.8rem;
  border-radius: 0.5rem;
}

.simplebar .simplebar-track {
  width: 0.8rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #4B4B4B;
}

.main .active {
  display: block;
}

.main .inactive {
  display: none;
}

.choice__mobile {
  display: none;
  /*  Mobile  */
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .choice__mobile {
    display: block;
  }
}

.choice__desktop {
  display: block;
  /*  Mobile  */
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .choice__desktop {
    display: none;
  }
}

.section__foot {
  padding-bottom: 30px;
}

.quiz {
  margin-bottom: 4em;
}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
.form .h3 {
  margin-bottom: 1.5rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .form .h3 {
    margin-bottom: 2.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .h3 {
    font-size: 4.6rem;
    line-height: 1.2;
    margin-bottom: 2.5rem;
  }
}
.form p {
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .form p {
    margin-bottom: 3.7rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form p {
    margin-bottom: 0;
  }
}
.form .form__head {
  margin-bottom: 3.6rem;
  /*  Tablet  */
  /*  Mobile  */
}

@media (max-width: 1023px) {
  .form .form__head {
    text-align: center;
    margin-bottom: 3.1rem;
  }
}
@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__head {
    margin-bottom: 2rem;
  }
}
.form .form__list {
  display: none;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .form .form__list {
    display: block;
  }
}
.form .form__label {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}

.form .form__label + .form__controls {
  margin-top: 1rem;
}

.form .form__field + .form__field {
  margin-top: 1.4rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__field + .form__field {
    margin-top: 0.8rem;
  }
}
.form .form__field + .form__checkboxes {
  margin-top: 1.8rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__field + .form__checkboxes {
    margin-top: 1.3rem;
  }
}
.form .form__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -1.4rem -0.8rem;
  /*  mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__row {
    display: block;
  }
}
.form .form__col {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding: 0 1.4rem 0.8rem;
  /*  mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__col {
    max-width: 100%;
  }
}
.form .form__body {
  margin-bottom: 2.8rem;
  /*  Mobile  */
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
  .form .form__body {
    margin-bottom: 3.3rem;
  }
}
.form .form__btn {
  min-width: 17rem;
  padding: 1.3rem 2rem 1.4rem;
}

.form .form__actions {
  text-align: center;
}

.form .form__slider {
  display: none;
  /*  Tablet  */
}

@media (max-width: 1023px) {
  .form .form__slider {
    display: block;
  }
}
/*.form .entry__actions .error__response { position: absolute; left: 0; right: 0; }
*/
.form--error .form__field--error label {
  font-weight: bold;
  color: #D2ff00;
}

.form--error .form__field--error input {
  border: 3px solid #D2ff00;
}

.form--error .form__field--error select {
  border: 3px solid #D2ff00;
}

.form--error .form__required {
  color: #1AD1D3;
}

.checkbox.error label:before {
  border: 3px solid #D2ff00;
}

/*.form--error .btn { opacity: .4; pointer-events: none; }*/
.product .product__info {
  padding: 3.4rem 2.5rem;
}

.product .product__image {
  max-height: 100%;
}

.score_sweep {
  margin-bottom: 4em;
}

.feature .feature__image {
  border-radius: 5px;
}

.round_card .section__title {
  position: relative;
}

.section-def .section__title {
  position: relative;
}